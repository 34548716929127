.image{
    margin-left:240px!important;
}
.test1{
    margin-left:-370px!important
}

.bold{
    font-weight:600!important;
    font-size:20px;
}


@media only screen and (max-width: 800px) {
   .image{
       margin-left:90px!important;
   }
   .test1{
       margin-left:80px!important;
   }
  
  .image1{
      margin-left:85px!important;
  }
  .text{
    margin-left:85px!important;
  }

  .list1{
      margin-left:85px!important;
  }

  .copy{
      margin-left:28px!important;
  }
  .foot{
      display: flex !important;
      font-size:12px!important;
  }
  .bold{
      font-size:14px!important;
      margin-left:5px;
  }
  .footer{
    font-size:10px!important;
  }
  .test1{
      font-size:12px!important;
  }
  .rights{
      font-size:12px!important;
  }
}
@media (min-width: 1400px){
    .container{
        max-width:1428px;
    }
}
